import React from "react";

import download from "downloadjs";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Check, Copy, Download, Edit, Edit3, Eye, Inbox, RefreshCcw, Trash } from "react-feather";
import api from "../../../../service/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
const ListButton = ({
    status,
    id,
    openDeleteModal,
    openCloneModal,
    currentPage,
    invoice_number,
    rawData,
    refresh,
    setLoading,
    setError,
    setData,
    setNotif
}) => {
    const navigate = useNavigate();
    /** download pdf */
    const downloadPDF = async (id, quotation_number) => {
        const response = await api.getpdf("tms/generate-quotation-pdf/" + id);
        download(response, quotation_number, "application/pdf");
    };

    const downloadInvoicePDF = async (id, invoice_number) => {
        const response = await api.getpdf("tms/generate-invoice-pdf/" + id);
        download(response, invoice_number, "application/pdf");
    };

    const regenerateInvoice = async (id) => {
        try {
            setLoading(true);
            setError();
            const response = await api.get("tms/generate-invoice/" + id);
            if(response.status === 'ok'){
                setNotif({
                    notifMsg: response.message ?? 'Regenerating.',
                    open: true,
                    severity: "success",
                });
            } else {
                setNotif({
                    notifMsg: response.message ?? 'Something went wrong in the server.',
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) { 
            setLoading(false);
            setData([])
            // setError(error.message);
            // console.log(error);
            // console.log('response', error, error.response);
            setNotif({
                notifMsg: error.response.data.message ?? 'Something went wrong in the server.',
                open: true,
                severity: "danger",
            });
        }
        refresh()
    };

    /** permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    return (
        <>
            {permission?.includes("QUOTATION-EDIT") &&
                rawData?.invoice_status?.toUpperCase() !== 'PAID' &&
                (
                <>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <a
                                href={`/tms/quotations/${rawData?.status_key?.toUpperCase() == "DRAFT" ? 'draft-edit' : 'edit'}/${id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Edit 
                                        size="20"
                                        color="#6c757d"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                       
                </>
            )}
            {permission?.includes("QUOTATION-CLONE") && rawData?.status_key?.toUpperCase() != "DRAFT" && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Clone</Tooltip>}
                >
                    <span>
                        <Copy
                            style={{ cursor: "pointer" }}
                            className="me-1 text-primary"
                            size="20"
                            color="#6c757d"
                            onClick={() => openCloneModal(id)}
                        />
                    </span>
                </OverlayTrigger>
            )}
            {status === "PENDING" && (
                <>
                    {/* {permission?.includes("QUOTATION-EDIT") &&
                        moment().format("YYYY-MM-DD") < rawData.cut_off_edit && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <a
                                href={`/tms/quotations/${id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Edit 
                                        size="20"
                                        color="#6c757d"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )} */}
                    {permission?.includes("QUOTATION-DELETE") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <span>
                                <Trash
                                    style={{ cursor: "pointer" }}
                                    className="me-1 text-danger"
                                    size="20"
                                    color="#6c757d"
                                    onClick={() => openDeleteModal(id)}
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                </>
            )}
            {permission?.includes("QUOTATION-READ") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View quotation</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/quotations/view/${id}?option=view?page=${currentPage}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <Eye size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {status === "PENDING" && permission?.includes("QUOTATION-READ") && permission?.includes("QUOTATION-APPROVER") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Approve</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/quotations/view/${id}?option=approval&page=${currentPage}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <Check size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Download</Tooltip>}
            >
                <Download
                    className="me-1"
                    style={{ cursor: "pointer" }}
                    size="20"
                    color="#6c757d"
                    onClick={() => downloadPDF(id, invoice_number)}
                />
            </OverlayTrigger>
            {status === "APPROVED" && permission?.includes("REGENERATE-INVOICE") && rawData?.has_invoice === false && (
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Regenerate</Tooltip>}
                >
                    <RefreshCcw
                        className="me-1"
                        style={{ cursor: "pointer" }}
                        size="20"
                        color="#6c757d"
                        onClick={() => regenerateInvoice(id)}
                    />
                </OverlayTrigger>
            )}
            {status === "APPROVED" && rawData?.has_invoice === true && (
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Download Invoice</Tooltip>}
                >
                    <Inbox
                        className="me-1"
                        style={{ cursor: "pointer" }}
                        size="20"
                        color="#6c757d"
                        onClick={() => downloadInvoicePDF(rawData?.has_invoice_id, rawData?.has_invoice_num)}
                    />
                </OverlayTrigger>
            )}

            {permission?.includes("QUOTATION-CHANGE-TRADE-CATEGORY") && status !== "DRAFT" && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit trade category</Tooltip>}
                >
                    <a
                        href={`/tms/quotations/trade-category-edit/${id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="me-1"
                    >
                        <span>
                            <Edit3 size="20" color="#6c757d" />
                        </span>
                    </a>
                </OverlayTrigger>
            )}
        </>
    );
};
export default ListButton;
