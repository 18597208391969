const tableData = [

];

const tableColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Test date",
        accessor: "test_date",
        Cell: ({ value }) => <div className="text-nowrap">{value}</div>,
    },
    {
        Header: "Trade category",
        id: "trade_category",
        accessor: (data) => {
            return <>{data?.trade_category || "-"}</>;
        },
    },
    {
        Header: "Passed",
        accessor: "passed_students",
    },
    {
        Header: "Absent",
        accessor: "absent_students",
    },
    {
        Header: "Fail",
        accessor: "fail_students",
    },
    {
        Header: "NC",
        accessor: "nc_students",
    },
    {
        Header: "Total students",
        accessor: "student_count",
    },
    
];

export { tableData, tableColumns };
