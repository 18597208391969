// Guards
import AuthGuard from "../../components/guards/AuthGuard";
import AddEditBatch from "./Batch/Form/AddEditBatch";
import AttendanceBatches from "./Batch/CategoryComponent/AttendanceBatches";
import CompletedBatches from "./Batch/CategoryComponent/CompletedBatches";
import PendingBatches from "./Batch/CategoryComponent/PendingBatches";
import ResultBatches from "./Batch/CategoryComponent/ResultBatches";

import AddEditCompany from "./Company/AddEditCompany";
import Companies from "./Company/Company";
import CompanyDetails from "./Company/CompanyDetails";
import AddEditCourse from "./Course/AddEditCourse";
import Course from "./Course/Course";
import AddEditEnrolment from "./Enrolments/AddEditEnrolment";
import EnrolmentDetails from "./Enrolments/EnrolmentDetails";

import TmsLayout from "./Layout/TmsLayout";
import StudentAllocation from "./StudentAllocation/StudentAllocation";
import Attendance from "./Attendance/Attendance";
import Result from "./Results/Result";
import Schedules from "./Schedules/Schedules";
import TrainingDateSchedules from "./Schedules/TrainingDateSchedules";

import ViewBatchStudents from "./ViewBatchStudents/ViewBatchStudents";
import EnrolledComponent from "./Enrolments/StatusComponents/EnrolledComponent";
import EnrollmentTrainingComponent from "./Enrolments/StatusComponents/EnrollmentTrainingComponent";
import EnrolledCompletedComponent from "./Enrolments/StatusComponents/EnrolledCompletedComponent";
import EnrolledClassAllocatedComponent from "./Enrolments/StatusComponents/EnrolledClassAllocatedComponent";
import EnrolledWaitingResultComponent from "./Enrolments/StatusComponents/EnrolledWaitingResultComponent";
import Renewal from "./Renewal/Renewal";
import ViewRenewal from "./Renewal/ViewRenewal";
import RenewalSent from "./Renewal/RenewalSent";
import RenewalReject from "./Renewal/RenewalReject";
import EnrollmentSummaryComponent from "./Enrolments/StatusComponents/EnrollmentSummaryComponent";
import SummaryBatches from "./Batch/CategoryComponent/SummaryBatches";
import AddStudentToClass from "./StudentAllocation/AddStudentToClass/AddStudentToClass";
import Quotation from "./Sales/Quotation/Quotation";
import AddEditQuotation from "./Sales/Quotation/Forms/AddEditQuotation";

import { TmsUsers } from "./Settings/TmsUsers";
import { TmsRoles } from "./Settings/TmsRoles";
import { TmsPermissions } from "./Settings/TmsPermissions";

//Shared
import { AddEditUser } from "../IntelliSuite/Users/AddEditUser";
import { AddEditRole } from "../IntelliSuite/Roles/AddEditRole";
import { AddEditPermission } from "../IntelliSuite/Permissions/AddEditPermission";
import QuotationsDetails from "./Sales/Quotation/Details/QuotationsDetails";
import InvoiceDetails from "./Sales/Invoice/Details/InvoiceDetails";
import Payment from "./Sales/Invoice/Payments/Payment";
import ManageStudent from "./Sales/Invoice/InvoiceStudents/ManageStudent";

import Payments from "./Sales/Payments/Payments";
import CommonDashboard from "./Dashboard/CommonDashboard";
import CreditNoteRequest from "./Sales/CreditNotes/CreditNoteRequest/CreditNoteRequest";

import ApprovedCreditNotes from "./Sales/CreditNotes/ApprovedCreditNotes";
import SummaryCreditNotes from "./Sales/CreditNotes/SummaryCreditNotes";
import CreditNoteDetails from "./Sales/CreditNotes/CreditNoteDetails/CreditNoteDetails";
import CreditNotePayment from "./Sales/CreditNotes/CreditNotePayment/CreditNotePayment";
import PendingForSalesDirectorApproval from "./Sales/CreditNotes/PendingForSalesDirectorApproval";
import PendingForFinanceDirectorApproval from "./Sales/CreditNotes/PendingForFinanceDirectorApproval";
import InvoiceOperation from "./Sales/Invoice/InvoiceOperation";
import InvoiceReport from "./Sales/Invoice/InvoiceReport";
import StudentChangeHistory from "./Sales/StudentChangeHistory/StudentChangeHistory";
import SendEmail from "./SendEmail/SendEmail";
import { EnrolledMarkResults } from "./Enrolments/StatusComponents/EnrolledMarkResults";
import SalesRevenueComponent from "./Statistic/StatusComponents/SalesRevenueComponent";
import PaidCompanyComponent from "./Statistic/StatusComponents/PaidCompanyComponent";
import InvoiceGeneratedComponent from "./Statistic/StatusComponents/InvoiceGeneratedComponent";

import AddEditAdhoc from "./Sales/Ad-hoc/Forms/AddEditAdhoc";
import AddEditAdditional from "./Sales/Additional/Forms/AddEditAdditional";
import EditQuotationStudent from "./Sales/Quotation/Forms/EditQuotationStudent";
import EnrolmentAdjustmentComponent from "./Statistic/StatusComponents/EnrolmentAdjustmentComponent";
import SalesSummaryByDocumentType from "./Sales/SalesSummaryByDocumentType/SalesSummaryByDocumentType";
import EnrollmentSummaryReportComponent from "./Enrolments/StatusComponents/EnrollmentSummaryReportComponent";
import EnrolledPendingConfirmation from "./Enrolments/StatusComponents/EnrolledPendingConfirmation";
import RoadshowOperation from "./Roadshow/RoadshowOperation";
import AddEditRoadshow from "./Roadshow/Forms/AddEditRoadshow";
import RoadshowPayment from "./Roadshow/Forms/RoadshowPayment";
import PendingCreditTermApprovalForSalesDirector from "./Sales/CreditTerm/PendingCreditTermApprovalForSalesDirector";
import Page401 from "../../pages/auth/Page401";
import CourseFee from "./Sales/CourseFees/CourseFee";
import SchedulingCalendar from "./Schedules/SchedulingCalendar";
import EnrollmentsNotPaidComponent from "./Statistic/StatusComponents/EnrollmentsNotPaidComponent";
import MonthlyReportCETCoretrade from "./Statistic/StatusComponents/MonthlyReportCETCoretrade";
import PassingRate from "./Passing Rate/PassingRate";

const TmsRoutes = {
    path: "/tms",
    element: (
        <AuthGuard>
            <TmsLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "",
            element: (
                <AuthGuard>
                    <CommonDashboard />
                </AuthGuard>
            ),
        },
        {
            path: "companies",
            element: (
                <AuthGuard>
                    <Companies />
                </AuthGuard>
            ),
        },
        {
            path: "companies/:id",
            element: (
                <AuthGuard>
                    <AddEditCompany />
                </AuthGuard>
            ),
        },
        {
            path: "companies/view/:id",
            element: (
                <AuthGuard>
                    <CompanyDetails />
                </AuthGuard>
            ),
        },
        {
            path: "courses",
            element: (
                <AuthGuard>
                    <Course />
                </AuthGuard>
            ),
        },
        {
            path: "courses/:id",
            element: (
                <AuthGuard>
                    <AddEditCourse />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/enrolled",
            element: (
                <AuthGuard>
                    <EnrolledComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/training",
            element: (
                <AuthGuard>
                    <EnrollmentTrainingComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/completed",
            element: (
                <AuthGuard>
                    <EnrolledCompletedComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/class-allocated",
            element: (
                <AuthGuard>
                    <EnrolledClassAllocatedComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/pending-confirmation",
            element: (
                <AuthGuard>
                    <EnrolledPendingConfirmation />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/waiting-for-result",
            element: (
                <AuthGuard>
                    <EnrolledWaitingResultComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/summary",
            element: (
                <AuthGuard>
                    <EnrollmentSummaryComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/summary/report",
            element: (
                <AuthGuard>
                    <EnrollmentSummaryReportComponent />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/mark-results",
            element: (
                <AuthGuard>
                    <EnrolledMarkResults />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/:id",
            element: (
                <AuthGuard>
                    <AddEditEnrolment />
                </AuthGuard>
            ),
        },
        {
            path: "enrolments/view/:id",
            element: (
                <AuthGuard>
                    <EnrolmentDetails />
                </AuthGuard>
            ),
        },
        {
            path: "batches/pending",
            element: (
                <AuthGuard>
                    <PendingBatches />
                </AuthGuard>
            ),
        },
        {
            path: "batches/attendance",
            element: (
                <AuthGuard>
                    <AttendanceBatches />
                </AuthGuard>
            ),
        },
        {
            path: "batches/waiting-for-result",
            element: (
                <AuthGuard>
                    <ResultBatches />
                </AuthGuard>
            ),
        },
        {
            path: "batches/completed",
            element: (
                <AuthGuard>
                    <CompletedBatches />
                </AuthGuard>
            ),
        },
        {
            path: "batches/:id",
            element: (
                <AuthGuard>
                    <AddEditBatch />
                </AuthGuard>
            ),
        },
        {
            path: "batches/student-allocate/:id",
            element: (
                <AuthGuard>
                    <StudentAllocation />
                </AuthGuard>
            ),
        },
        {
            path: "batches/attendance/:id", // id is batch id
            element: (
                <AuthGuard>
                    <Attendance />
                </AuthGuard>
            ),
        },
        {
            path: "batches/results/:id", // id is batch id
            element: (
                <AuthGuard>
                    <Result />
                </AuthGuard>
            ),
        },
        {
            path: "batches/students/:id",
            element: (
                <AuthGuard>
                    <ViewBatchStudents />
                </AuthGuard>
            ),
        },
        {
            path: "batches/summary",
            element: (
                <AuthGuard>
                    <SummaryBatches />
                </AuthGuard>
            ),
        },
        {
            path: "batches/add-student-to-class/:id",
            element: (
                <AuthGuard>
                    <AddStudentToClass />
                </AuthGuard>
            ),
        },
        {
            path: "batches/send-email/:id",
            element: (
                <AuthGuard>
                    <SendEmail />
                </AuthGuard>
            ),
        },
        {
            path: "schedules/calendar",
            element: (
                <AuthGuard>
                    <SchedulingCalendar />
                </AuthGuard>
            ),
        },
        // {
        //     path: "schedules",
        //     element: (
        //         <AuthGuard>
        //             <Schedules />
        //         </AuthGuard>
        //     ),
        // },
        // {
        //     path: "schedules/training-dates",
        //     element: (
        //         <AuthGuard>
        //             <TrainingDateSchedules />
        //         </AuthGuard>
        //     ),
        // },
        {
            path: "renewals/list",
            element: (
                <AuthGuard>
                    <Renewal />
                </AuthGuard>
            ),
        },
        {
            path: "renewals/sent-list",
            element: (
                <AuthGuard>
                    <RenewalSent />
                </AuthGuard>
            ),
        },
        // {
        //     path: "renewals/reject-list",
        //     element: (
        //         <AuthGuard>
        //             <RenewalReject />
        //         </AuthGuard>
        //     ),
        // },
        {
            path: "renewals/:id",
            element: (
                <AuthGuard>
                    <ViewRenewal />
                </AuthGuard>
            ),
        },
        {
            path: "quotations",
            element: (
                <AuthGuard>
                    <Quotation />
                </AuthGuard>
            ),
        },
        {
            path: "quotations/:id",
            element: (
                <AuthGuard>
                    <AddEditQuotation />
                </AuthGuard>
            ),
        },
        {
            path: "quotations/view/:id",
            element: (
                <AuthGuard>
                    <QuotationsDetails />
                </AuthGuard>
            ),
        },
        {
            path: "quotations/:method/:id",
            element: (
                <AuthGuard>
                    <EditQuotationStudent />
                </AuthGuard>
            ),
        },
        {
            path: "invoices",
            element: (
                <AuthGuard>
                    <InvoiceOperation />
                </AuthGuard>
            ),
        },
        {
            path: "invoice-report",
            element: (
                <AuthGuard>
                    <InvoiceReport />
                </AuthGuard>
            ),
        },
        {
            path: "invoices/view/:id", // invoice id
            element: (
                <AuthGuard>
                    <InvoiceDetails />
                </AuthGuard>
            ),
        },
        {
            path: "invoices/payments/:id", // invoice id
            element: (
                <AuthGuard>
                    <Payment />
                </AuthGuard>
            ),
        },
        {
            path: "invoices/manage-students/:id", // invoice id
            element: (
                <AuthGuard>
                    <ManageStudent />
                </AuthGuard>
            ),
        },
        {
            path: "users",
            element: (
                <AuthGuard>
                    <TmsUsers />
                </AuthGuard>
            ),
        },
        {
            path: "users/:id",
            element: (
                <AuthGuard>
                    <AddEditUser />
                </AuthGuard>
            ),
        },
        {
            path: "roles",
            element: (
                <AuthGuard>
                    <TmsRoles />
                </AuthGuard>
            ),
        },
        {
            path: "roles/:id",
            element: (
                <AuthGuard>
                    <AddEditRole />
                </AuthGuard>
            ),
        },
        {
            path: "permissions",
            element: (
                <AuthGuard>
                    <TmsPermissions />
                </AuthGuard>
            ),
        },
        {
            path: "permissions/:id",
            element: (
                <AuthGuard>
                    <AddEditPermission />
                </AuthGuard>
            ),
        },
        {
            path: "approved-credit-notes",
            element: (
                <AuthGuard>
                    <ApprovedCreditNotes />
                </AuthGuard>
            ),
        },
        {
            path: "sales-director-credit-notes",
            element: (
                <AuthGuard>
                    <PendingForSalesDirectorApproval />
                </AuthGuard>
            ),
        },
        {
            path: "credit-term-approval",
            element: (
                <AuthGuard>
                    <PendingCreditTermApprovalForSalesDirector />
                </AuthGuard>
            ),
        },
        {
            path: "sales-summary-by-document-type",
            element: (
                <AuthGuard>
                    <SalesSummaryByDocumentType />
                </AuthGuard>
            ),
        },
        {
            path: "finance-director-credit-notes",
            element: (
                <AuthGuard>
                    <PendingForFinanceDirectorApproval />
                </AuthGuard>
            ),
        },
        {
            path: "summary-credit-notes",
            element: (
                <AuthGuard>
                    <SummaryCreditNotes />
                </AuthGuard>
            ),
        },
        {
            path: "credit-note-view/:reroute/:id",
            element: (
                <AuthGuard>
                    <CreditNoteDetails />
                </AuthGuard>
            ),
        },
        {
            path: "invoice-credit-notes-requests/:id",
            element: (
                <AuthGuard>
                    <CreditNoteRequest />
                </AuthGuard>
            ),
        },
        {
            path: "payments",
            element: (
                <AuthGuard>
                    <Payments />
                </AuthGuard>
            ),
        },
        {
            path: "credit-note-refund/:id",
            element: (
                <AuthGuard>
                    <CreditNotePayment />
                </AuthGuard>
            ),
        },
        {
            path: "student-change-histories",
            element: (
                <AuthGuard>
                    <StudentChangeHistory />
                </AuthGuard>
            ),
        },
        {
            path: "statistic/enrolment-adjustment",
            element: (
                <AuthGuard>
                    <EnrolmentAdjustmentComponent />
                </AuthGuard>
            ),
        },
        {
            path: "statistic/sales-revenue",
            element: (
                <AuthGuard>
                    <SalesRevenueComponent />
                </AuthGuard>
            ),
        },
        {
            path: "statistic/paid-company",
            element: (
                <AuthGuard>
                    <PaidCompanyComponent />
                </AuthGuard>
            ),
        },
        {
            path: "statistic/all-invoice-generated",
            element: (
                <AuthGuard>
                    <InvoiceGeneratedComponent />
                </AuthGuard>
            ),
        },
        {
            path: "statistic/enrollments-not-paid",
            element: (
                <AuthGuard>
                    <EnrollmentsNotPaidComponent />
                </AuthGuard>
            ),
        },
        {
            path: "statistic/monthly-report-cet-coretrade",
            element: (
                <AuthGuard>
                    <MonthlyReportCETCoretrade />
                </AuthGuard>
            ),
        },
        {
            path: "passing-rate-summary",
            element: (
                <AuthGuard>
                    <PassingRate />
                </AuthGuard>
            ),
        },
        {
            path: "ad-hoc/:id",
            element: (
                <AuthGuard>
                    <AddEditAdhoc />
                </AuthGuard>
            ),
        },
        {
            path: "additional/:id/:action",
            element: (
                <AuthGuard>
                    <AddEditAdditional />
                </AuthGuard>
            ),
        },
        {
            path: "roadshow",
            element: (
                <AuthGuard>
                    <RoadshowOperation />
                </AuthGuard>
            ),
        },
        {
            path: "roadshow/:id",
            element: (
                <AuthGuard>
                    <AddEditRoadshow />
                </AuthGuard>
            ),
        },
        {
            path: "roadshow/payments/:id", // invoice id
            element: (
                <AuthGuard>
                    <RoadshowPayment />
                </AuthGuard>
            ),
        },
        {
            path: "course_fees",
            element: (
                <AuthGuard>
                    <CourseFee />
                </AuthGuard>
            ),
        },
        {
            path: "401",
            element: <Page401 />,
        },
    ],
};

export default TmsRoutes;
