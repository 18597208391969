import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";

import {
    Form,
    Card,
    Container,
    Row,
    Col,
    Alert,
    Button,
    Badge,
} from "react-bootstrap";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Search } from "react-feather";
import download from "downloadjs";

import { tableColumns } from "./Data.js";
import { Lists } from "./Lists.js";
import api from "../../../service/api.js";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment";
const PassingRate = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    /** Permissions */

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const [isValidDateRange, setIsValidDateRange] = useState(true);
    const [toDatePickerValue, setToDatePickerValue] = useState(
        moment().endOf("month").format("YYYY-MM-DD")
    );
    const [fromDatePickerValue, setFromDatePickerValue] = useState(
        moment().startOf("month").format("YYYY-MM-DD")
    );
    const [isSubmitting, setIsSubmitting] = useState(false);

    const searchFields = {
        start_date: fromDatePickerValue,
        end_date: toDatePickerValue,
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getPassingData(searchParam);
    };
    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
    ? state?.previousSearchValue
    : null;


    const getPassingData = useCallback(async (search) => {
        setLoading(true);
        try {
            const response = await api.get("tms/passing-rate-summary", search);
            setData(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }
        setLoading(false);
    }, []);
    const getInputValue = (value) => {
        return value != ""
            ? moment(value).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD");
    };
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const generateExcelFile = async (search) => {
        setIsSubmitting(true);
        try {
            const response = await api.getexcel(
                "tms/passing-rate-summary/excel-export",
                search
            );

            if (response.type.includes("application/json")) {
                const jsonData = JSON.parse(await response.text());

                if (jsonData.success) {
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "success",
                    });
                } else {
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "danger",
                    });
                }
            } else if (response.size !== 0) {
                download(
                    response,
                    "Passing_Rate_Summary_" +
                        search.start_date + 
                        "_to_" +
                        search.end_date,
                    "application/xlsx"
                );
            } else {
                setError("No data to be exported.");
            }
        } catch (error) {
            setError(error.message);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        getPassingData(searchValue);
    }, [getPassingData]); //  eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        // console.log("Search: ", searchValue);

        var start_date = new Date(searchValue.start_date);
        var end_date = new Date(searchValue.end_date);
        setIsValidDateRange(start_date <= end_date);
    },[searchValue]);
    const helmetTitle = "Monthly Passing Rate";

    return (
        <React.Fragment>
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{title}</h1>
                <Card>
                    <Card.Header>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        <Row className="flex-row ms-md-1">
                            <Col md={4} className="p-md-0">
                                <Form.Group>
                                    <DatePicker
                                        id="from-date-picker"
                                        name="from_date"
                                        className={`form-control`}
                                        // disabledDate={d => !d || moment(d).isAfter(moment()) || moment(d).isBefore(`${minSelectableYear}`)}
                                        // picker="year"
                                        placeholder="From date"
                                        defaultValue={moment().startOf("month")}
                                        allowClear={false}
                                        onChange={(date) => {
                                            setSearchValue({
                                                ...searchValue,
                                                start_date: getInputValue(
                                                    date ?? ""
                                                ), //event.target.value != '' ? event.target.value : moment().format("YYYY-MM"),
                                            });
                                        }}
                                        style={{ minHeight: "2.5rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                md={1}
                                className="d-flex align-items-center justify-content-center m-0 p-0"
                            >
                                <Badge
                                    bg="info"
                                    className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0"
                                >
                                    To
                                </Badge>
                            </Col>
                            <Col md={4} className="p-md-0">
                                <Form.Group>
                                    <DatePicker
                                        id="to-date-picker"
                                        name="to_date"
                                        // disabledDate={d => !d || moment(d).isAfter(moment(fromDatePickerValue).add(maxYearRange, 'years')) || moment(d).isBefore(moment(fromDatePickerValue).subtract(maxYearRange, 'years')) || moment(d).isBefore(`${minSelectableYear}`) || moment(d).isAfter(moment())}
                                        // picker="year"
                                        className={`form-control`}
                                        placeholder="To date"
                                        defaultValue={moment().endOf("month")}
                                        allowClear={false}
                                        onChange={(date) => {
                                            setSearchValue({
                                                ...searchValue,
                                                end_date: getInputValue(
                                                    date ?? ""
                                                ),
                                            });
                                        }}
                                        style={{ minHeight: "2.5rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                md={2}
                                className="d-flex align-items-center justify-content-center justify-content-md-start mt-2 mt-md-0"
                            >
                                <Button
                                    className="float-start me-1"
                                    variant="info"
                                    type="submit"
                                    size="lg"
                                    onClick={() => search()}
                                    disabled={!isValidDateRange}
                                >
                                    <Search className="feather" />
                                </Button>

                                {!isSubmitting && (
                                    <Button
                                        className="float-end me-1"
                                        variant="info"
                                        type="submit"
                                        size="lg"
                                        onClick={() =>
                                            generateExcelFile(searchValue)
                                        }
                                        disabled={!isValidDateRange}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} />
                                    </Button>
                                )}

                                {isSubmitting && (
                                    <div
                                        className="spinner-border"
                                        role="status"
                                        style={{
                                            minWidth: "2rem",
                                            minHeight: "2rem",
                                        }}
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        {loading && <div>Loading...</div>}
                        {!loading && data?.data && (
                            <Lists
                                getPassingData={getPassingData}
                                columns={tableColumns}
                                rawData={data}
                                status={status}
                                title={title}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default PassingRate;
