import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
    Table,
} from "react-bootstrap";
import { Plus, Trash } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { DeleteModal } from "../../../components/DeleteModal";
import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";
import { toUpperInput } from "../../../utils/utility";

const FormRow = ({
    setFieldValue,
}) => {
    const [similarUEN, setSimilarUEN] = useState([]);
    const [editCompany, setEditCompany] = useState();
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState();
    const [initValues, setInitialValues] = useState({
        name: "",
        uen: "",
        contacts: [],
        sales_persons: [],
        full_address: "",
        addtnl_address:[],
        remarks: "",
    });
    const [rerender, setRerender] = useState(false);
    const [modalInfoContact, setModalInfoContact] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [modalInfoSales, setModalInfoSales] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [salesPersons, setSalesPersons] = useState();
    const addContacts = () => {
        const updateInitialValues = { ...initValues };
        const { contacts } = updateInitialValues;
        contacts.push({
            name: "",
            email: "",
            contact_number: "",
            fax: "",
            ato: false,
            attc: false,
        });
        setInitialValues(updateInitialValues);
    };

    const addAddress = () => {
        const updateInitialValues = { ...initValues };
        const { addtnl_address } = updateInitialValues;

        addtnl_address.push({
            address: "",
            default: false
        });
        setInitialValues(updateInitialValues);
    };

    const deleteAddress = (index) => {
        const updateInitialValues = { ...initValues };
        const { addtnl_address } = updateInitialValues;
        addtnl_address.splice(index, 1);
        setInitialValues(updateInitialValues);
    };

    const deleteContacts = (index) => {
        const updateInitialValues = { ...initValues };
        const { contacts } = updateInitialValues;
        contacts.splice(index, 1);
        setInitialValues(updateInitialValues);
    };

    const addSalesPerson = (e) => {
        e.preventDefault();
        let updateInitialValues = { ...initValues };
        const { sales_persons } = updateInitialValues;
        sales_persons.push({ user_id: "", attc: false, ato: false, person_in_charge: false });
        setInitialValues(updateInitialValues);
    };

    const deleteSalesPerson = (index) => {
        let updateInitialValues = { ...initValues };
        const { sales_persons } = updateInitialValues;
        sales_persons.splice(index, 1);
        setInitialValues(updateInitialValues);
    };

    const [notify, setNotify] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const navigate = useNavigate();
    let { id } = useParams();

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        uen: Yup.string().required(),
        customer_code: Yup.string().required(),
    });

    const handleChange = (event, stateObject, index) => {
        const targetValue =
            event.target.type === "checkbox" || event.target.type === "radio"
                ? event.target.checked
                : event.target.value;
       
        let updateInitialValues = { ...initValues };
        // console.log(updateInitialValues.stateObject);
        if (
            stateObject !== undefined &&
            stateObject !== "" &&
            (index === undefined || index === "")
        ) {
            let parseStateObject = updateInitialValues[stateObject];
            parseStateObject[event.target.name] = targetValue;
            setInitialValues(updateInitialValues);
            return;
        }
        if (
            stateObject !== undefined &&
            stateObject !== "" &&
            index !== undefined &&
            index !== ""
        ) {
            // console.log(event.target.name);
            let parseStateObject = updateInitialValues[stateObject];
            if (event.target.type === "checkbox") {

                updateInitialValues.addtnl_address.map((data, i) => {
                    if (i === index) {
                        parseStateObject[i][event.target.name] = targetValue;
                        setInitialValues(updateInitialValues);
                    }else {
                        parseStateObject[i][event.target.name] = false;
                        setInitialValues(updateInitialValues);
                    }
                })
            }else if (event.target.type === "radio") {
                updateInitialValues.sales_persons.map((data, i) => {
                    if (i === index) {
                        parseStateObject[i][event.target.name] = targetValue;
                        setInitialValues(updateInitialValues);
                    }else {
                        parseStateObject[i][event.target.name] = false;
                        setInitialValues(updateInitialValues);
                    }
                })
            }
            else {
                let parseStateObject = updateInitialValues[stateObject];
                parseStateObject[index][event.target.name] = targetValue;
                setInitialValues(updateInitialValues);
            }
            return;
        }
        setInitialValues({
            ...initValues,
            [event.target?.name]: targetValue,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await schema
            .validate(initValues, { abortEarly: false })
            .then(async function () {
                try {
                    //frontend checker for if salesperson item was added but not assigned
                    let stop = false
                    initValues.sales_persons?.map((data, id) => {
                        if(data.user_id === "" || data.user_id === null){
                            setNotify({
                                notifMsg: `Please select a salesperson/remove item ${id + 1}`,
                                open: true,
                                severity: "danger",
                            });
                            stop = true
                        }
                    })
                    //do not call api if true
                    if(stop){
                        return
                    }
                    
                    if (id !== "add") {
                        await submitEditCompany(initValues);
                    } else {
                        await submitAddCompany(initValues);
                    }

                    // Added this because passing the states in the navigate
                    // function does not show the notification after navigate.
                    const delay = ms => new Promise(res => setTimeout(res, ms));
                    // window.scrollTo(0, 0);
                    setNotify({
                        notifMsg: `Successfully ${
                            id === "add" ? "added" : "updated"
                        } company. Redirecting...`,
                        open: true,
                        severity: "success",
                    });
                    await delay(2000);

                    navigate("/tms/companies", {
                        state: {
                            open: true,
                            notifMsg: `Successfully ${
                                id === "add" ? "added" : "updated"
                            } company`,
                            severity: "success",
                        },
                    });
                } catch (error) {
                    setNotify({
                        notifMsg: error.response?.data?.message,
                        open: true,
                        severity: "danger",
                    });
                    return;
                }
            })
            .catch(function (err) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                setErrors(validationErrors);
                return;
            });
    };
    const submitAddCompany = (values) => {
        try {
            return api.post("tms/companies", {
                name: values.name,
                uen: values.uen,
                customer_code: values.customer_code,
                address: values.address,
                contacts: values.contacts,
                sales_persons: values.sales_persons,
                full_address: values.full_address,
                remarks: values.remarks,
                addtnl_address: values.addtnl_address,
            });
        } catch (error) {
            setNotify({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const submitEditCompany = (values) => {
        return api.put(`tms/companies/${id}`, {
            name: values.name,
            uen: values.uen,
            customer_code: values.customer_code,
            address: values.address,
            contacts: values.contacts,
            sales_persons: values.sales_persons,
            full_address: values.full_address,
            remarks: values.remarks,
            addtnl_address: values.addtnl_address,
        });
    };

    const deleteContactApi = async (id) => {
        try {
            const response = await api.delete(
                `tms/companies/delete-contact/${id}`
            );

            if (response?.success) {
                setNotify({
                    notifMsg: "Successfully deleted contact",
                    open: true,
                    severity: "success",
                });
                setRerender(!rerender);
            }
        } catch (error) {
            setNotify({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const deleteSalesApi = async (id) => {
        try {
            const response = await api.delete(
                `tms/companies/delete-sales/${id}`
            );

            if (response?.success) {
                setNotify({
                    notifMsg: "Successfully deleted sales person",
                    open: true,
                    severity: "success",
                });
                setRerender(!rerender);
            }
        } catch (error) {
            setNotify({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const checkSimilarUEN = async (value) => {
        try {
            const response = await api.get(`tms/similar-uen`, {uen: value});
            setSimilarUEN(response.data?.data || { response: [] });
        } catch (error) {
            setSimilarUEN({response: []});
        }
    };

    useEffect(() => {
        if (id && id !== "add") {
            const getCompany = async () => {
                try {
                    const response = await api.get(`tms/companies/${id}`, {});
                    setEditCompany(response.data);
                } catch (error) {}
            };
            getCompany();
        }
        const getSalesPerson = async () => {
            try {
                const companyResponse = await api.get(`tms/sales-persons`, {});
                setSalesPersons(companyResponse.data);
            } catch (error) {}
        };
        getSalesPerson();
    }, [id, rerender]);

    useEffect(() => {
        if (editCompany) {
            setInitialValues({
                name: editCompany?.name,
                uen: editCompany?.uen,
                customer_code: editCompany?.customer_code ?? "",
                address: editCompany?.address || {
                    blockOrStreetName: "",
                    buildingOrHouseNumber: "",
                    unitNumber: "",
                    postalCode: "",
                },
                contacts: editCompany?.contacts,
                sales_persons: editCompany?.sales_persons,
                full_address: editCompany?.full_address,
                remarks: editCompany?.remarks,
                addtnl_address: editCompany?.addtnl_address ?? [],
            });
        }
    }, [editCompany]);
    return (
        <>
            <Card>
                <Card.Body>
                    <Col md={12}>
                        {similarUEN && similarUEN.length > 0  && 
                            <>
                                <h3>Similar UEN</h3>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                        <th>#</th>
                                        <th>UEN</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {similarUEN.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.uen}</td>
                                            <td>{item.name}</td>
                                            <td>{item.is_active ? 'Active' : 'Inactive'}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        }
                    </Col>
                    <Form noValidate onSubmit={handleSubmit}>
                        {serverError && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">
                                    {serverError}
                                </div>
                            </Alert>
                        )}
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Name
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        size="lg"
                                        placeholder="Name"
                                        value={initValues?.name || ""}
                                        isInvalid={Boolean(errors?.name)}
                                        onChange={handleChange}
                                        onInput={toUpperInput}
                                    />
                                </Form.Group>
                                {errors?.name && (
                                    <div className="text-danger">
                                        {errors?.name}
                                    </div>
                                )}
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        UEN{" "}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="uen"
                                        size="lg"
                                        placeholder="UEN"
                                        value={initValues?.uen || ""}
                                        isInvalid={Boolean(errors?.uen)}
                                        onChange={(e) => {
                                            handleChange(e);
                                            checkSimilarUEN(e.target.value)
                                          }}
                                        onInput={toUpperInput}
                                    />
                                </Form.Group>
                                {errors?.uen && (
                                    <div className="text-danger">
                                        {errors?.uen}
                                    </div>
                                )}
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Customer code{" "}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="customer_code"
                                        size="lg"
                                        placeholder="Customer code"
                                        value={initValues?.customer_code || ""}
                                        isInvalid={Boolean(errors?.customer_code)}
                                        onChange={handleChange}
                                        onInput={toUpperInput}
                                    />
                                </Form.Group>
                                {errors?.customer_code && (
                                    <div className="text-danger">
                                        {errors?.customer_code}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <h4>Address:</h4>

                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={12}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text className="w-25">
                                                Default address :
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                size="lg"
                                                disabled
                                                name="full_address"
                                                value={initValues?.full_address?.replace(/\n/g, ' ')}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                    {initValues.addtnl_address &&
                                        initValues?.addtnl_address?.map((address, i) => {
                                            return (
                                                <div key={`more_address-${i}`}>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Additional address {i+1} 
                                                                </Form.Label>
                                                                
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    size="lg"
                                                                    name="address"
                                                                    value={
                                                                        address?.address || ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            e,
                                                                            "addtnl_address",
                                                                            i
                                                                        )
                                                                    }
                                                                    onInput={toUpperInput}
                                                                />
                                                            
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={1}
                                                            className=" d-flex flex-row align-items-center"
                                                        >
                                                            <Form.Group className="m-2">
                                                                <Form.Check
                                                                    label="Default"
                                                                    type="checkbox"
                                                                    size="lg"
                                                                    name="default"
                                                                    id="default"
                                                                    checked={
                                                                        address?.default || false
                                                                    }
                                                                    value={address?.default || false}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            e,
                                                                            "addtnl_address",
                                                                            i
                                                                        )
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={1}
                                                            className="d-flex flex-row align-items-left"
                                                        >
                                                            <Button
                                                                variant="danger"
                                                                type="button"
                                                                onClick={() => {
                                                                    deleteAddress(i);
                                                                }}
                                                            >
                                                                <Trash
                                                                    size={15}
                                                                    className="m-0"
                                                                ></Trash>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                
                                            );
                                    })}
                                
                                    <Button
                                        variant="success"
                                        className="mb-4"
                                        type="button"
                                        onClick={addAddress}
                                    >
                                        <Plus size={15} className="m-0"></Plus>
                                    </Button>
                                
                            </Col>
                            
                            
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="remarks"
                                        size="lg"
                                        placeholder="Remarks"
                                        value={initValues?.remarks || ""}
                                        isInvalid={Boolean(errors?.remarks)}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h4>Contacts:</h4>

                        {initValues.contacts &&
                            initValues.contacts?.map((contact, i) => {
                                return (
                                    <div key={`contact-${i}`}>
                                        <Row>
                                            <Col md={2}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Name
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        size="lg"
                                                        name="name"
                                                        value={
                                                            contact?.name || ""
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                "contacts",
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Email
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        size="lg"
                                                        name="email"
                                                        value={
                                                            contact?.email || ""
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                "contacts",
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Contact number
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        size="lg"
                                                        name="contact_number"
                                                        value={
                                                            contact?.contact_number ||
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                "contacts",
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Fax number
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        size="lg"
                                                        name="fax"
                                                        value={
                                                            contact?.fax || ""
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                "contacts",
                                                                i
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>

                                            
                                            <Col
                                                md={1}
                                                className="d-flex flex-row align-items-center"
                                            >
                                                <Button
                                                    variant="danger"
                                                    type="button"
                                                    onClick={() => {
                                                        deleteContacts(i);
                                                        
                                                    }}
                                                >
                                                    <Trash
                                                        size={15}
                                                        className="m-0"
                                                    ></Trash>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        <Button
                            variant="success"
                            className="mb-4"
                            type="button"
                            onClick={addContacts}
                        >
                            <Plus size={15} className="m-0"></Plus>
                        </Button>
                        <Table className="table table-borderless" >
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Sales Person</th>
                                    <th></th>
                                    <th className="text-nowrap">Person In Charge</th>
                                </tr>
                            </thead>
                            <tbody>
                            {initValues.sales_persons?.map((sales, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-nowrap">
                                            <select
                                                className="selectpicker form-control"
                                                placeholder="Sales person"
                                                name="user_id"
                                                value={sales?.user_id}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        "sales_persons",
                                                        i
                                                    )
                                                }
                                            >
                                                {/* <option value="3c9e32f2-5f14-4936-880a-560e766d9c40">
                                                    User 1
                                                </option>
                                                <option value="759815bc-c58d-4370-8bb8-0cbd9bd01696">
                                                    User 2
                                                </option>
                                                <option value="e7348823-5370-4884-bba8-342886fb9fbd">
                                                    User 3
                                                </option> */}
                                                <option value="">
                                                    --- select ---
                                                </option>
                                                {salesPersons?.map(
                                                    (sales, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={sales.id}
                                                            >
                                                                {sales.name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                            {errors?.sales_person && (
                                                <div className="text-danger">
                                                    {errors?.sales_person[i]}
                                                </div>
                                            )}
                                        </td>
                                                {/* <Col
                                                    md={2}
                                                    className=" d-flex flex-row align-items-center"
                                                >
                                                    <Form.Group className="m-2">
                                                        <Form.Check
                                                            label="ATO"
                                                            type="checkbox"
                                                            size="lg"
                                                            name="ato"
                                                            checked={
                                                                sales?.ato || false
                                                            }
                                                            value={sales?.ato || false}
                                                            // onBlur={handleBlur}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    e,
                                                                    "sales_persons",
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="m-2">
                                                        <Form.Check
                                                            label="ATTC"
                                                            type="checkbox"
                                                            size="lg"
                                                            name="attc"
                                                            value={sales?.attc || false}
                                                            checked={
                                                                sales?.attc || false
                                                            }
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    e,
                                                                    "sales_persons",
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col> */}

                                        <td>
                                            <Button
                                                variant="danger"
                                                type="button"
                                                onClick={() => {
                                                    if (sales?.id) {
                                                        setModalInfoSales({
                                                            open: true,
                                                            notifMsg:
                                                                "Are you sure you want to delete this sales person?",
                                                            id: sales?.id,
                                                        });
                                                    } else {
                                                        deleteSalesPerson(i);
                                                    }
                                                }}
                                            >
                                                <Trash
                                                    size={15}
                                                    className="m-0"
                                                ></Trash>
                                            </Button>
                                        </td>
                                        <td>
                                            <input 
                                            type="radio" 
                                            name="person_in_charge"
                                            value={sales.person_in_charge} 
                                            checked={sales.person_in_charge}
                                            onChange={(e) =>
                                                handleChange(
                                                    e,
                                                    "sales_persons",
                                                    i
                                                )
                                            }
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                        
                        <Button
                            variant="success"
                            className="mb-4 mt-2"
                            type="button"
                            onClick={addSalesPerson}
                        >
                            <Plus size={15} className="m-0"></Plus>
                        </Button>
                        <hr />
                        <div className="d-flex flex-row align-items-center p-2">
                            <Button
                                variant="primary"
                                size="lg"
                                type="submit"
                                className="col-md-3 "
                            >
                                Save
                            </Button>

                            <Button
                                variant="danger"
                                size="lg"
                                onClick={() => navigate("/tms/companies")}
                                className="col-md-3  ms-2 me-2"
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            <SnackbarAlert notif={notify} setNotif={setNotify} />
            <DeleteModal
                modalInfo={modalInfoContact}
                setModalInfo={setModalInfoContact}
                api={deleteContactApi}
            />
            <DeleteModal
                modalInfo={modalInfoSales}
                setModalInfo={setModalInfoSales}
                api={deleteSalesApi}
            />
        </>
    );
};

const AddEditCompany = () => {
    let { id } = useParams();
    const helmetTitle = `${id === "add" ? "Add" : "Edit"} company`;

    return (
        <React.Fragment>
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {helmetTitle}
                </h1>
                <Row>
                    <Col lg="12">
                        <FormRow />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AddEditCompany;
